import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Banner from "../components/Banner";
import RecentlyWatched from "../components/RecentlyWatched";
import Demos from "../components/Demos";

const BlogPostSection = styled.section`
  box-sizing: border-box;
  max-width: 700px;
  margin: 0 auto;
  padding: 10px;

  h2 {
    font-size: 32px;
    border-bottom: 2px solid var(--color);
  }

  article {
    display: flex;
    flex-direction: column;
    margin-top: 1.7rem;
    padding-left: 18px;

    h3 {
      position: relative;
      font-size: 26px;
      font-size: 24px;
      margin: 0;
      padding: 0;

      &:before {
        position: absolute;
        left: -20px;
        top: 0px;
        content: "-";
        height: 10px;
        width: 10px;
      }
    }

    p {
      padding: 0;
      font-size: 20px;
      opacity: 0.9;
      margin: 8px 0;
    }

    a {
      align-self: flex-end;

      &:visited {
        color: green;
      }
      button {
        background: #fff;
        text-decoration: underline;
        padding: 8px;
        background: transparent;
        border: none;
        color: var(--color);
        font-weight: bold;
        border-radius: 1px;
        font-family: "EB Garamond", serif;
        font-size: 22px;
        cursor: pointer;
      }
    }
  }
`;

function IndexPage(props) {
  const [isLoading, setIsLoading] = React.useState(true);

  const nodes = props.data.allMarkdownRemark.edges
    .filter(
      (el) => el.node.frontmatter.isPublished && !el.node.frontmatter.isPassword
    )
    .map((el) => el.node)
    .filter((node) => node.frontmatter.isGuide)
    .sort(
      (node1, node2) =>
        node1.frontmatter.guideOrder - node2.frontmatter.guideOrder
    );

  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <title>Avid Language Learning</title>
        <link rel="canonical" href="https://avidlanguagelearning.com" />
        <meta
          name="description"
          content="Using video to help you learn languages better."
        ></meta>
      </Helmet>
      <Banner />
      <RecentlyWatched setIsLoading={setIsLoading} />
      <Demos isLoading={isLoading} />
      <BlogPostSection>
        <RelativeContainer isLoading={isLoading}>
          <h2 id="guides">Guides</h2>
          {nodes.map((node) => {
            return (
              <article key={node.fields.slug}>
                <h3>{node.frontmatter.title}</h3>
                <p>{node.frontmatter.description}</p>
                <Link to={node.fields.slug}>
                  <button>Read More...</button>
                </Link>
              </article>
            );
          })}
        </RelativeContainer>
      </BlogPostSection>
    </>
  );
}

const RelativeContainer = styled.div`
  position: relative;
  margin: 0;
  padding: 0;

  opacity: ${(props) => (props.isLoading ? 0 : 1)};
`;

export const pageQuery = graphql`
  query MyQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            description
            isPublished
            isGuide
            guideOrder
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;

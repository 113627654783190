import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 700px;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;
  opacity: ${(props) => (props.isLoading ? 0 : 1)};

  h2 {
    font-size: 32px;
    border-bottom: 2px solid var(--color);
  }

  .video-list-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 47%);
      gap: 20px;
    }
    a {
      text-decoration: none;
      .video-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        p.title {
          color: var(--color);
          height: 70px;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          padding: 0;
          margin: 0;
          font-weight: bold;
          font-size: 18px;
          width: 90%;
          &:hover {
            text-decoration: underline;
          }
        }

        figure {
          position: relative;
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
          width: 100%;
          height: 0;
          padding-top: calc(9 / 16 * 100%);
          position: relative;
          overflow: hidden;
          border-radius: 3px;
          border: 1px solid var(--border-color);

          &:hover + p {
            text-decoration: underline;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 3px;
            cursor: pointer;
            border: 1px solid #222;
            animation: fadeIn 0.5s ease;
          }
          /* Lang */
          span {
            position: absolute;
            top: -1px;
            right: -1px;
            background: #000;
            border: 1px solid #222;
            border-top: 4px solid #222;
            padding: 5px 8px;
            text-transform: uppercase;
            color: #fff;
            border-radius: 2px;
            border: 1px solid #4e4d4d;
          }
        }

        .progress {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 101%;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 3px;
        }
      }
    }
  }
`;

const videos = [
  {
    uuid: "54e0e5b3-0fa9-48ca-93c7-a0cea6b3549d",
    url: "https://www.youtube.com/watch?v=FzOpWjhktdw",
    publishedAt: "2024-02-13T08:00:08.000Z",
    title: "The Future of Learning English",
    channelId: "UCZJJTxA36ZPNTJ1WFIByaeA",
    channelThumbnail:
      "https://yt3.ggpht.com/ytc/AIf8zZT0ol95lAgqKOzG7hmrLG149uIao1Enmn_02B3O=s800-c-k-c0x00ffffff-no-rj",
    channelTitle: "Learn English with Bob the Canadian",
    lang: "en",
    subTrack: ".en",
    thumbnail: "https://i.ytimg.com/vi/FzOpWjhktdw/mqdefault.jpg",
    createdAt: "2024-02-25T15:30:15.913Z",
    updatedAt: "2024-02-25T15:30:15.913Z",
  },
  {
    uuid: "3f7e7aa0-58c6-4ae6-9e39-545b20b853e5",
    url: "https://www.youtube.com/watch?v=mWLM7ghZR0E",
    publishedAt: "2023-12-17T18:30:08.000Z",
    title:
      "🚀 20 Frases SUPERÚTILES en ESPAÑOL para todos los días + PDF GRATIS 🗎  || Mejora tu español",
    channelId: "UC30I0ZZ54oOVBYeXJtafYIA",
    channelThumbnail:
      "https://yt3.ggpht.com/5qoC3cm3WYs6meobPgWZuPsNDRpAOBpCVb9UsUlvJyWfAvgbAu1VGRhe16zueneWiFFX_5Q=s800-c-k-c0x00ffffff-no-rj",
    channelTitle: "María Español",
    lang: "es",
    subTrack: ".es",
    thumbnail: "https://i.ytimg.com/vi/mWLM7ghZR0E/mqdefault.jpg",
    createdAt: "2024-02-23T21:58:32.022Z",
    updatedAt: "2024-02-23T21:58:32.022Z",
  },
  {
    uuid: "5a6adba3-9db6-4600-8827-bf0aed574b5f",
    url: "https://www.youtube.com/watch?v=BC6Wa27VVz0",
    publishedAt: "2023-07-25T14:42:22.000Z",
    title: "Comment apprendre le français dans le bon ordre ?",
    channelId: "UCI4xp8qHD1MDErkqxb1dPbA",
    channelThumbnail:
      "https://yt3.ggpht.com/eXGlDuw4UB3R61fMSWAg6PSEXBJnRfL1ONJUm4b2anqt2t-L6pA_2YtCP8T_yuyLMJiDz7ZP=s800-c-k-c0x00ffffff-no-rj",
    channelTitle: "innerFrench",
    lang: "fr",
    subTrack: ".fr",
    thumbnail: "https://i.ytimg.com/vi/BC6Wa27VVz0/mqdefault.jpg",
    subtitles: [],
    createdAt: "2024-02-24T14:27:49.033Z",
    updatedAt: "2024-02-24T14:27:49.033Z",
  },
  {
    uuid: "1a864386-3fd7-4da9-a58d-efb5dc24eac0",
    url: "https://www.youtube.com/watch?v=_Eu3Nt8VgWc",
    publishedAt: "2024-02-04T13:04:02.000Z",
    title:
      "Deutsche Verben in gehobener Sprache (Deutsch für Fortgeschrittene B2 C1)",
    channelId: "UCthmoIZKvuR1-KuwednkjHg",
    channelThumbnail:
      "https://yt3.ggpht.com/u_pOzSgbqHe9qHFcu7PHypeSRlw5quRD-r88PKuPM-S_w9GC3QcoG1AVo-xkXVzjqFxJiFJKCAU=s800-c-k-c0x00ffffff-no-rj",
    channelTitle: "Deutsch mit Rieke",
    lang: "de",
    subTrack: ".de-DE",
    thumbnail: "https://i.ytimg.com/vi/_Eu3Nt8VgWc/mqdefault.jpg",
    subtitles: [],
    createdAt: "2024-02-27T19:44:03.183Z",
    updatedAt: "2024-02-27T19:44:03.183Z",
  },

  {
    uuid: "8a4b3d93-5758-48d8-8159-5450c95539da",
    url: "https://www.youtube.com/watch?v=kFzA1tm3uDM",
    publishedAt: "2024-02-21T16:30:07.000Z",
    title:
      "Italian grammar Q&A: la D eufonica, Scusa! Scusi!, il passato remoto, ecc. (IT Subtitles)",
    channelId: "UCnVc-IW8Q98qFmQcXla5FdQ",
    channelThumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_nthtrNImkEBw1d2I0IXEiCK9Dn7Lb2zNPgnVdkjA=s800-c-k-c0x00ffffff-no-rj",
    channelTitle: "Learn Italian with Lucrezia",
    lang: "it",
    subTrack: ".it",
    thumbnail: "https://i.ytimg.com/vi/kFzA1tm3uDM/mqdefault.jpg",
    subtitles: [],
    createdAt: "2024-03-05T13:02:44.323Z",
    updatedAt: "2024-03-05T13:02:44.323Z",
  },
  {
    uuid: "0b460cf6-e597-4409-a326-07ba10dc74c7",
    url: "https://www.youtube.com/watch?v=AKOemOyfXVg",
    publishedAt: "2023-12-28T18:00:04.000Z",
    title: "Quais os melhores métodos para aprender uma língua?",
    channelId: "UCDqH89_8ThHUWatP2sLC1Qw",
    channelThumbnail:
      "https://yt3.ggpht.com/dms_wpu4mXTv9h9UA_OqaYYOxoXVmCXjoIgjShIYH6jUHIaoOv8R3MBAaiI5uDIdGFJlXwzx=s800-c-k-c0x00ffffff-no-rj",
    channelTitle: "Portuguese With Leo",
    lang: "pt",
    subTrack: "pt-PT",
    thumbnail: "https://i.ytimg.com/vi/AKOemOyfXVg/mqdefault.jpg",
    subtitles: [],
    createdAt: "2024-03-05T18:17:56.575Z",
    updatedAt: "2024-03-05T18:17:56.575Z",
  },
  {
    uuid: "09eb31be-4b04-46b3-a836-57ad44ffeac1",
    url: "https://www.youtube.com/watch?v=lt6Ek9OvpO4",
    publishedAt: "2023-09-29T13:58:55.000Z",
    title:
      "Complexe Passieve Zinnen: met ER en/of met extra werkwoorden!! (NT2 - B1/B2)",
    channelId: "UCWiOWGpwDZbJuSesaxMHkRg",
    channelThumbnail:
      "https://yt3.ggpht.com/ytc/AIdro_nxsL2sq9hZ-AVj9tDXyhfeC11q_x_EoDkgtJFgyQ=s800-c-k-c0x00ffffff-no-rj",
    channelTitle: "Dutchies to be - Learn Dutch with Kim",
    lang: "nl",
    subTrack: ".nl",
    thumbnail: "https://i.ytimg.com/vi/lt6Ek9OvpO4/mqdefault.jpg",
    subtitles: [],
    createdAt: "2024-03-05T18:29:22.460Z",
    updatedAt: "2024-03-05T18:29:22.460Z",
  },
];
function Demos(props) {
  const { isLoading } = props;
  return (
    <Container isLoading={isLoading}>
      <h2>Demos</h2>

      <div className="video-list-container">
        {videos.map((vid, idx) => {
          return (
            <Link
              key={vid.uuid}
              alt={vid.title}
              to={`/app/catalog/video/${vid.uuid}`}
            >
              <div className="video-card">
                <figure>
                  <img src={vid.thumbnail} alt={vid.title} />
                  <span>{vid.lang}</span>
                  <Progress uuid={vid.uuid} />
                </figure>

                <p className="title">{vid.title}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </Container>
  );
}

function Progress(props) {
  const { uuid } = props;

  const [progress, setProgress] = useState(null);

  useEffect(() => {
    setProgress(Number(localStorage.getItem(uuid)).toFixed(2) * 100 ?? 0.0);
  }, [uuid]);

  if (!progress) {
    return null;
  }

  return (
    <div
      className="progress"
      style={{
        width: `${progress}%`,
        borderRight: progress ? `2px double #dadada` : "none",
      }}
      title={`Progress (${progress}%)`}
    ></div>
  );
}

export default Demos;

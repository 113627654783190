import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import getRecentVideos from "../util/getRecentVideos";

const Container = styled.div`
  max-width: 700px;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;

  h2 {
    font-size: 32px;
    border-bottom: 2px solid var(--color);
  }

  .video-list-container {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 47%);
      gap: 20px;
    }
    a {
      text-decoration: none;
      .video-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        p.title {
          color: var(--color);
          height: 70px;
          margin-bottom: 10px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          padding: 0;
          margin: 0;
          font-weight: bold;
          font-size: 18px;
          width: 90%;
          &:hover {
            text-decoration: underline;
          }
        }

        figure {
          position: relative;
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
          width: 100%;
          height: 0;
          padding-top: calc(9 / 16 * 100%);
          position: relative;
          overflow: hidden;
          border-radius: 3px;
          border: 1px solid var(--border-color);

          &:hover + p {
            text-decoration: underline;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            border-radius: 3px;
            cursor: pointer;
            animation: fadeIn 0.5s ease;
          }
          /* Lang */
          span {
            position: absolute;
            top: -1px;
            right: -1px;
            background: #000;
            border: 1px solid #222;
            border-top: 4px solid #222;
            padding: 5px 8px;
            text-transform: uppercase;
            color: #fff;
            border-radius: 2px;
            border: 1px solid #4e4d4d;
          }
        }

        .progress {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 101%;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 3px;
        }
      }
    }
  }
`;

function RecentlyWatched(props) {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    setVideos(getRecentVideos());
    props.setIsLoading(false);
  }, [props]);

  if (!videos.length) {
    return null;
  }

  return (
    <Container>
      <h2>Recently Watched</h2>

      <div className="video-list-container">
        {videos.map((vid, idx) => {
          if (idx === 6) {
            return null;
          }

          const progress =
            Number(localStorage.getItem(vid.uuid)).toFixed(2) * 100 ?? 0.0;
          return (
            <Link
              key={vid.uuid}
              alt={vid.title}
              to={`/app/catalog/video/${vid.uuid}`}
            >
              <div className="video-card">
                <figure>
                  <img src={vid.thumbnail} alt={vid.title} />
                  <span>{vid.lang}</span>
                  <div
                    className="progress"
                    style={{
                      width: `${progress}%`,
                      borderRight: progress ? `2px double #dadada` : "none",
                    }}
                    title={`Progress (${progress}%)`}
                  ></div>
                </figure>

                <p className="title">{vid.title}</p>
              </div>
            </Link>
          );
        })}
      </div>
    </Container>
  );
}

export default RecentlyWatched;

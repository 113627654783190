import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const ImageContainer = styled.div`
  position: relative;
  height: 40vh;
  max-width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border: 1px solid #4e4d4d;
  /* background: #222; */
  overflow: hidden;
  border-radius: 3px;

  /* This div is created by the static image */
  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    filter: brightness(1.1);
  }

  h1 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    font-size: 50px;
    line-height: 50px;
    z-index: 10000;
    padding: 0;
    padding-top: 20px;
    box-sizing: border-box;
    margin: 0;
    color: #fff;
    color: var(--accent-bg);
    -webkit-text-stroke: 1px rgba(0, 0, 0, 0.2);
    font-weight: bold;
    background: var(--banner-bg);
    text-align: center;
  }

  @media screen and (max-width: 800px) {
    background: #222;
    h2 {
      font-size: 30px;
    }
  }
`;

const Banner = () => (
  <ImageContainer>
    <StaticImage
      placeholder="dominantColor"
      src="../images/europe2.jpg"
      alt="Charles Bridge"
    />

    <h1>Avid Language Learning</h1>
  </ImageContainer>
);

export default Banner;
